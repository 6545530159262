import React from 'react';

import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import TestimonialCmp from '../components/TestimonialCmp';
import { testimonialsTitle, testimonialsData } from '../data/testimonialsData';

const TestimonialsPage = () => (
  <Layout>
    <Seo title='Testimonials' />

    <div className='py-16 mt-5'>
      <div className='container px-4 mx-auto'>
        <h3 className='mb-3 text-3xl font-extrabold leading-tight text-center text-primary'>
          {testimonialsTitle}
        </h3>

        <hr className='w-64 h-1 p-0 m-0 mx-auto mb-10 opacity-25 gradient' />

        <div className='grid justify-center grid-cols-1 gap-4 md:grid-cols-3 dark-text'>
          {testimonialsData.map((testimonial, index) => (
            <TestimonialCmp
              content={testimonial.content}
              name={testimonial.name}
              key={index}
              imagePath={testimonial.imagePath}
              id={testimonial.id}
            />
          ))}
        </div>
      </div>
    </div>
  </Layout>
);

export default TestimonialsPage;

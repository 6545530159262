export const testimonialsTitle = 'What People Are Saying About Langino Law';

export const testimonialsData: Testimonial[] = [
  {
    id: 0,
    content: `I have known Adam for 15 years. We both served the community as Assistant Public Defenders, and later re-united at a national Plaintiffs firm. Our relationship began as professionals, and developed into a friendship. This transition allowed me to see Adam as not only a diligent, focused, and determined lawyer, but a caring individual who works extremely hard at achieving justice for his clients. Even though Adam has all of the skills in his tool box to serve his clients well, he is continuously striving to sharpen these skills, which only inure to the benefit of his clients.`,
    name: 'Nicholas C. Johnson',
    imagePath: '/images/testimonials/nicholas.jpg',
  },
  {
    id: 1,
    content: `Adam is a smart, compassionate, and skilled trial attorney. His razor-sharp intellect, charisma, and knowledge of the law is unrivaled in any courtroom he walks into, regardless of the stakes. I have partnered with Adam in many trials, and I know first-hand how his command of the courtroom wins cases. I recommend him without any reservation.`,
    name: 'Sabarish Neelakanta',
    imagePath: '/images/testimonials/sabarish.png',
  },
  {
    id: 2,
    content: `I've known Adam for many years, starting from his time working as an Assistant Public Defender in Florida. There are two characteristics about Adam  I personally like: first is his sense of pragmatism.  He is a problem-solver and that manifests itself whether we are playing chess or he is giving me career advice. The second and, maybe, most important characteristic about Adam  I like is his deep sense of humanism. He truly cares about people.  He loves to see those around him succeed. He is also a helper - always ready and willing to land a helping hand whenever he is called upon.`,
    name: 'Miche Jean',
    imagePath: '/images/testimonials/miche.jpg',
  },
  {
    id: 3,
    content: `Adam and I worked together at the same law firm for many years. That experience taught me that Adam is smart, tenacious, imaginative, hardworking, and focused on his clients' best interests. His morals and ethics are beyond repute. Adam possesses both the skills and the courage to try a case when the defense fails to negotiate a fair resolution.`,
    name: 'Wally McCall',
    imagePath: '',
  },
];

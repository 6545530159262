import React from 'react';

import { Testimonial } from '../types/Testimonial';

function TestimonialCmp({ id, content, name, imagePath }: Testimonial) {
  return (
    <div className='w-full px-4 mt-6' id={`testimonial-${id}`}>
      <div className='flex -mx-4'>
        <div className='px-4 '>
          <div className='p-4 text-white rounded-lg shadow bg-primary'>
            {content}
          </div>
          <div className='ml-4 -mt-1'>
            <svg
              height='20'
              width='20'
              viewBox='0, 0, 20, 20'
              className='text-white'
            >
              <polygon
                points='0,0 20,0 10,20'
                className='triangle'
                fill='#419BB5'
              ></polygon>
            </svg>
          </div>
          <div className='flex mt-2'>
            {imagePath !== undefined ? (
              <div className='flex-shrink-0 w-16 text-center'>
                <img className='rounded-full' src={imagePath} alt=''></img>
              </div>
            ) : null}
            <p className='ml-2 font-bold '>
              {name}
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCmp;
